import React, { useState, useEffect } from 'react'
import Layout from 'components/layout'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import * as queryString from 'query-string'
import AddToSlackButton from 'components/buttons/add-to-slack'
import SecondaryParagraph from 'components/text/secondary-paragraph'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SLACK_CLIENT_ID = process.env.GATSBY_SLACK_CLIENT_ID

const SecondPage = props => {
  const [userConfigId, setUserConfigId] = useState()
  const [loading, setLoading] = useState(false)

  // since gatsby hydrates HTML location.search is going to be undefined (it was pre-compiled)
  // we are updating it by using effect hook, to cause component re-render
  useEffect(() => {
    const { user_config_id: userConfigIdFromQuery } = queryString.parse(
      props.location.search
    )
    setUserConfigId(userConfigIdFromQuery)
    setLoading(true)
  }, [props.location.search])

  const slackAuthUrl =
    `https://slack.com/oauth/authorize?` +
    queryString.stringify({
      scope:
        `incoming-webhook,commands,bot,channels:read,emoji:read,groups:read,` +
        `reactions:read,team:read,users.profile:read,users:read.email,dnd:read,users:read,` +
        `chat:write:bot,reactions:write,links:read`,
      client_id: SLACK_CLIENT_ID,
      state: queryString.stringify({ userConfigId }),
    })

  return (
    <Layout>
      <Container>
        <h1>Step 2</h1>
        <div
          css={css`
            margin-bottom: 0.4rem;
          `}
        >
          <AddToSlackButton size={60} />
          <SecondaryParagraph>
            Toast cannot read your Slack messages.
          </SecondaryParagraph>
        </div>
      </Container>
    </Layout>
  )
}

export default SecondPage
